import React from 'react';
import { Container, Typography, Paper } from '@mui/material';

function MainPage() {
  return (
    <Container maxWidth="md">
      <Paper elevation={3} style={{ marginTop: '20px', padding: '20px' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Sensory processing, quality of life, and contextual factors associated with schoolchildren with developmental coordination disorder
        </Typography>
        <Typography variant="subtitle1" component="h2" gutterBottom style={{ marginTop: '20px' }}>
          Weber MD1, Cavalcante Neto JL2, Tudella E1
        </Typography>
        <Typography variant="body2">
          1 Department of Physiotherapy, Federal University of São Carlos, São Carlos, São Paulo, Brazil.<br />
          2 Department of Humanities Sciences, State University of Bahia, Jacobina, Bahia, Brazil.
        </Typography>
      </Paper>
      <Paper elevation={3} style={{ marginTop: '20px', padding: '20px' }}>
      <Typography variant="subtitle1" component="h2" gutterBottom style={{ marginTop: '20px', fontWeight: 'bold' }}>
          Background
        </Typography>
        <Typography variant="body2" align="justify">
          Developmental Coordination Disorder (DCD) is characterized by difficulties in the acquisition and improvement of fundamental and complex motor skills, such as climbing stairs, riding a bike, picking up an object, dressing, cutting with scissors, and engaging in physical activities. These difficulties are marked by clumsiness, slowness, and inaccurate motor performance, being prevalent in 5% to 8% of school-aged children worldwide. Considering their characteristics, it is possible that sensory processing is impaired in children with DCD, significantly restricting their participation. Therefore, health-related quality of life, dictated by physical, emotional, social, and school well-being, may be impaired in children with DCD. Although the etiology is not yet defined, some biological factors, such as prematurity and low birth weight, and environmental factors, such as socioeconomic level, housing conditions, education of the caregiver, and the nature of the school, may be possible risk factors for the child.         </Typography>
        <Typography variant="subtitle1" component="h2" gutterBottom style={{ marginTop: '20px', fontWeight: 'bold' }}>
          Objective
        </Typography>
        <Typography variant="body2" align="justify">
        To verify sensory processing and quality of life in children with Developmental Coordination Disorder, analyzing potential contextual factors associated between children with and without Developmental Coordination Disorder        </Typography>
        <Typography variant="subtitle1" component="h2" gutterBottom style={{ marginTop: '20px', fontWeight: 'bold' }}>
          Methods
        </Typography>
        <Typography variant="body2" align="justify">
        This project was submitted to and approved by the Directorate of Education and the Municipal Department of Education of São Carlos. It was also submitted to and approved by the Ethics Committee for Research at the Federal University of São Carlos, under CAAE 52286421.0.0000.5504, following the recommendations of Resolution 466/2012 of the National Health Council.
        </Typography>
        <Typography variant="body2" align="justify" style={{ marginTop: '10px' }}>
        This is a case-control study. The recruitment period began in August 2020. However, due to social isolation caused by the COVID-19 pandemic, acceptance from a school was only obtained in March 2022, and data collection began thereafter. Data collection took place in the spaces provided by the participating schools from March 2022 to June 2024. Between March and August 2023, recruitment and data collection were interrupted due to a temporary stay abroad for a sandwich period. In September 2023, recruitment and data collection resumed and continued until June 2024. All children whose parents authorized participation were assessed.
        </Typography>
        <Typography variant="body2" align="justify" style={{ marginTop: '10px' }}>
        The research procedure was as follows: State, municipal, and private schools, from the 1st to the 5th grade of elementary education in São Carlos, were contacted. In the schools that agreed to participate in the study, envelopes containing the following were distributed to the children: Informed Consent Form (ICF), the Developmental Coordination Disorder Questionnaire (DCDQ), the Short Sensory Profile 2, the Pediatric Quality of Life Inventory™ version 4.0 (PedsQL™ 4.0) (parent/caregiver version), and the sociodemographic questionnaire. During the delivery of the envelopes, it was explained to the children that they should hand them to their guardians for reading. The period for the return of the documents was pre-established with the pedagogical team, usually between seven and ten days. After checking which children received approval from their guardians through the ICF and the completed questionnaires, the researcher made a list containing the names, ages, and classes of the children. All children whose parents authorized participation were invited to take part in the evaluation.
        </Typography>
        <Typography variant="body2" align="justify" style={{ marginTop: '10px' }}>
        At school, the children (at a pre-scheduled time by the pedagogical team) were initially invited to participate through the Free and Informed Assent Form (TALE). Upon obtaining the children's consent, they were assessed using the Movement Assessment Battery for Children – Second edition (MABC-2) and the Pediatric Quality of Life Inventory™ version 4.0 (PedsQL™ 4.0) (child version). Administering the MABC-2 and the PedsQL™ 4.0 to a single child takes approximately 30 minutes and was therefore conducted in a single day. The assessment environment depended on the availability of space at each school, aiming to be as private as possible without external influences such as noise or the presence of other children. The collaborating researchers received training from the principal investigator, who is fully proficient in the instruments. A pilot study was conducted to verify interobserver agreement. After the study, feedback was provided to the schools with the results of the sensorimotor performance of all the evaluated children, and the results were forwarded to their respective guardians. The process of writing and delivering the reports is still ongoing.
        </Typography>
        <Typography variant="body2" align="justify" style={{ marginTop: '10px' }}>
        To minimize the risk of selection bias and allow comparability between the groups (minimizing potential confounding factors), all children - controls and cases - were recruited and assessed from the same population. Additionally, considering the geographic distribution of schools in the city of São Carlos (SP), data collection did not take place in a single region but rather covered schools from almost all areas of the city. There was a higher concentration in the schools in the northern and eastern regions, followed by those located in the central and southern regions. Therefore, the study population can be considered representative of the city. Of the 38 schools in the municipality of São Carlos (SP) that have Elementary Education I, 9 (23.7%) agreed to participate in the research, 15 (39.5%) declined participation, and 14 (36.8%) were not invited. Therefore, 24 (63.1%) of the schools were addressed, considering those that were invited. Of the nine schools that agreed to participate in the study, two (22.2%) are municipal, five (55.5%) are state, and two (22.2%) are private.
        </Typography>
        <Typography variant="body2" align="justify" style={{ marginTop: '10px' }}>
        A total of 1,562 envelopes were distributed. Of these, 762 (48.8%) were returned with authorization to participate, and 725 (46.4%) were evaluated following the eligibility criteria. All children whose guardians authorized participation and who met the eligibility criteria were assessed. The reasons for exclusion were: the child had transferred schools (n=19); the child did not agree to participate (n=8); the child was absent for more than 3 evaluation attempts (n=4); and the child had a health condition that prevented evaluation, namely cerebral palsy (n=2), intellectual disability (n=3), and upper limb agenesis (n=1). These data are being tabulated in an Excel spreadsheet and will be analyzed later using SPSS.
        </Typography>
        <Typography variant="body2" align="justify" style={{ marginTop: '10px' }}>
        The justification for distributing 1,562 questionnaires is that this was the number of children (within the study age range) available for data collection, as provided by the school management and coordination. Therefore, although the number of children enrolled in Elementary Education I is significantly higher, the number of teachers who authorized the research was lower, resulting in an “n” of 1,562.
        </Typography>
        <Typography variant="subtitle1" component="h2" gutterBottom style={{ marginTop: '20px', fontWeight: 'bold' }}>
          Funding
        </Typography>
        <Typography variant="body2" align="justify">
        This study was supported by a PhD grant from the Coordenação de Aperfeiçoamento de Pessoal de Nível Superior - Brasil (CAPES) - Finance Code 88887.717129/2022-00 and Grant 2020/12729-9, São Paulo Research Foundation (FAPESP).  
        </Typography>
        <Typography variant="subtitle1" component="h2" gutterBottom style={{ marginTop: '20px', fontWeight: 'bold' }}>
          Conflict of Interest
        </Typography>
        <Typography variant="body2" align="justify">
          The authors report there are no competing interests to declare.
        </Typography>
        <Typography variant="subtitle1" component="h2" gutterBottom style={{ marginTop: '20px', fontWeight: 'bold' }}>
          Data repository
        </Typography>
        <Typography variant="body2" align="justify">
          The data repository is available at the link below.
        </Typography>
        <Typography variant="body1" style={{ marginTop: '20px' }}>
          <a href="/nenem-ufscar/" target="_blank" rel="noopener noreferrer">Click here to see the data</a>
        </Typography>

      </Paper>
    </Container>
  );
}

export default MainPage;
